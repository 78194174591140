<template>
  <div>
    <v-alert border="top" :color="alertaColor" elevation="10" outlined :type="alertaTipo" v-if="alerta == true"></v-alert>

    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>

    <v-row class="pa-2">
      <v-col><v-card class="pa-2" elevation="3">Caja #: {{ cajaId }}</v-card></v-col>
      <v-col><v-card class="pa-2" elevation="3">Estado: {{ estadoCaja }}</v-card></v-col>
      <v-col><v-card class="pa-2" elevation="3">Fecha: {{ fechaCaja }}</v-card></v-col>
      <v-col><v-card class="pa-2" elevation="3">Usuario: {{ usuarioCaja }}</v-card></v-col>
    </v-row>

    <v-row class="pa-2">
      <v-col cols="12">
        <v-card elevation="4">
          <v-btn color="info" small class="ma-2 white--text " @click="descargarCaja()">
            <v-icon class="pr-2" dark>
              mdi-printer
            </v-icon>
            Imprimir
          </v-btn>

          <v-btn color="info" small class="ma-2 white--text" @click="cerrarCaja()" v-if="codEstadoCaja == 'CJ_ABIERTA'">
            <v-icon> mdi-close</v-icon>
            Cerrar caja
          </v-btn>

          <v-btn color="info" small v-if="btnAbrirCaja && codEstadoCaja == 'CJ_CERRADA'" class="ma-2 white--text"
            @click="abrirCaja()">
            <v-icon left>mdi-cash-register</v-icon>Abrir caja
          </v-btn>

          <v-btn color="info" small class="ma-2 white--text" @click="facturasCaja()">
            <v-icon>mdi-script-text</v-icon>
            Facturas
          </v-btn>

          <v-btn v-if="codEstadoCaja == 'CJ_ABIERTA'" color="info" small class="ma-2 white--text"
            @click="ingresarAnticipo()">
            <v-icon left>mdi-account-cash</v-icon>
            Ingresar anticipo
          </v-btn>
        </v-card>
      </v-col>
    </v-row>

    <v-row class="pa-1">
      <v-col cols="5">
        <v-card max-width="100%">
          <v-card-title>
            Resumen por forma de pago
          </v-card-title>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-center">
                    Forma pago
                  </th>
                  <th class="text-center">
                    # Transacciones
                  </th>
                  <th class="text-center">
                    Monto
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in dtTotalesCaja">
                  <td>{{ item.forma_pago }}</td>
                  <td align="center">{{ item.num_trans }}</td>
                  <td align="right">{{ item.monto }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
      </v-col>

      <v-col cols="5">
        <v-card max-width="100%">
          <v-card-title>
            Depósitos en efectivo
          </v-card-title>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th></th>
                  <th class="text-center">
                    Comprobante
                  </th>
                  <th class="text-center">
                    Monto depositado
                  </th>
                  <th class="text-center">
                    Fecha del depositado
                  </th>
                  <th class="text-center">
                    Observaciones
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in datosDepEfe">
                  <td>
                    <v-btn small @click="
                      tipoDeposito = 'efe';
                    borrarDeposito(
                      item.fin_account_trans_id,
                      item.gl_reconciliation_id
                    );
                    ">
                      <v-icon center dark>
                        mdi-delete
                      </v-icon>
                    </v-btn>
                  </td>
                  <td>{{ item.referencia }}</td>
                  <td align="right">{{ item.monto }}</td>
                  <td>{{ item.transaction_date }}</td>
                  <td>{{ item.comments }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>

          <v-btn color="info" x-small class="ma-2 white--text" @click.stop="abrirFormDeposito">
            Nuevo dep&oacute;sito
            <v-icon right dark>
              mdi-plus
            </v-icon>
          </v-btn>
        </v-card>
      </v-col>
    </v-row>
    <!-- dialogo de ingreso de depositos en efectivo -->
    <v-dialog v-model="formDepEfe" persistent max-width="400px">
      <v-card>
        <v-card-title>
          <v-icon class="mx-2">mdi-cash</v-icon>
          <span class="headline">{{ tituloDeposito }}</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-form ref="formDeposito">
              <v-row>
                <v-col class="pt-0 pb-0" cols="12" sm="10" md="10">
                  <v-select v-model="datosDepEfe.fin_account_id" small item-text="fin_account_name"
                    item-value="fin_account_id" :items="cuentasBancarias" label="Cuenta bancaria" :rules="finAcctgRules"
                    :disabled="noEditarDep"></v-select>
                </v-col>

                <v-col cols="12" class="pt-0 pb-0">
                  <v-text-field v-model="datosDepEfe.descripcion" label="Descripción*" type="text" :disabled="noEditarDep"
                    small></v-text-field>
                </v-col>

                <v-col cols="12" class="pt-0 pb-0">
                  <v-text-field v-model="datosDepEfe.fechaDeposito" label="Fecha del depósito*" type="date"
                    :disabled="noEditarDep" small :rules="fechaDepRules"></v-text-field>
                </v-col>

                <v-col cols="12" sm="8" md="8" class="pt-0 pb-0">
                  <v-text-field v-model="datosDepEfe.referencia" label="Numero de comprobante*" type="number"
                    :disabled="noEditarDep" @keydown="validarNumComp" v-mask="'##########'" :rules="referenciaRules"
                    small></v-text-field>
                </v-col>

                <v-col cols="12" sm="8" md="8" class="pt-0 pb-0">
                  <v-text-field v-model="datosDepEfe.valor" label="Valor*" small :disabled="noEditarValor" type="text"
                    :rules="valorRules"></v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
          <small>*campos obligatorios</small>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="formDepEfe = false">
            <v-icon>mdi-cancel</v-icon>
            Cancelar
          </v-btn>
          <v-btn v-if="formBorrarDepCheq == false" color="blue darken-1" solid :loading="lbDepEfe" :disabled="lbDepEfe"
            class="white--text" @click="guardarDeposito()">
            <v-icon>mdi-content-save</v-icon>
            Guardar
          </v-btn>

          <v-btn v-if="formBorrarDepCheq == true" color="blue darken-1" solid class="white--text"
            @click="borrarDeposito(datosDepEfe.fin_account_trans_id)">
            <v-icon>mdi-delete</v-icon>
            Borrar Depósito
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- fin dialogo depositos en efectivo -->

    <!-- dialogo de lista de facturas -->
    <v-dialog v-model="formFacturas" persistent max-width="1280px">
      <v-card>
        <v-card-title>
          <span class="headline">Facturas</span>
        </v-card-title>

        <v-data-table width="20%" :items="dtFacturasCaja" :headers="headers_facturas_caja" :options.sync="options"
          :loading="loadingTable" loading-text="Cargando registros..." class="elevation-1" hide-default-footer
          disable-pagination>
          <template v-slot:item.shipment_method_type_id="{ item }">
            <div v-if="item.shipment_method_type_id != 'NO_SHIPPING'">
              DOM
            </div>
          </template>
        </v-data-table>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="formFacturas = false">
            Salir
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- fin de dialogo de facturas -->
    <!-- dialogo de aniticipos-->
    <v-dialog v-model="dialogAnticipos" persistent max-width="980px">
      <v-card class="pb-4 pt-0">
        <v-card-title>
          <span><v-icon left>mdi-account-cash</v-icon>Anticipo de cliente</span>
        </v-card-title>
        <FormNuevoPagoRecibido @dialogAnticipoCerrado="
          dialogAnticipos = false;
        cargarTransaccionesCaja();
        " :popupDialog="popDialog"></FormNuevoPagoRecibido>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="
            dialogAnticipos = false;
          popDialog = false;
          ">
            Cerrar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-card>
      <v-card-title>
        <v-text-field v-model="buscar" append-icon="mdi-magnify" label="Buscar" single-line hide-details></v-text-field>
      </v-card-title>
      <v-data-table width="20%" :items="dtTransCaja" :headers="headers" :options.sync="options" :loading="loadingTable"
        loading-text="Cargando registros..." class="elevation-1" hide-default-footer disable-pagination :search="buscar">
        <template v-slot:item.actions="{ item }">
          <v-btn v-if="(item.payment_method_type_id == 'PERSONAL_CHECK' ||
            item.payment_method_type_id == 'CHEQUE_POSFECHADO') &&
            item.depChequeLbanco == ''
            " @click="abrirFormCheque(item.transaccion_id, item.monto_pago)" icon color="red">
            <v-icon color="orange">mdi-table-edit</v-icon>
          </v-btn>

          <v-btn v-if="(item.payment_method_type_id == 'PERSONAL_CHECK' ||
            item.payment_method_type_id == 'CHEQUE_POSFECHADO') &&
            item.depChequeLbanco != ''
            " @click="
    borrarDepCheque(
      item.transaccion_id,
      item.monto_pago,
      item.depChequeLbanco
    )
    " icon small>
            <v-icon color="orange">mdi-delete</v-icon>
          </v-btn>
        </template>

        <template v-slot:item.payment_id="{ item }">
          <a target="_blank" :href="`/ver-pago/${item.payment_id}`">
            {{ item.payment_id }}
          </a>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>
<script>
import { mapState, mapMutations, mapActions, mapGetters } from "vuex";
import FormNuevoPagoRecibido from "./FormNuevoPagoRecibido.vue";

export default {
  name: "FormCajaComponent",
  props: {
    caja: {
      type: String,
      default: "",
    },
  },
  components: { FormNuevoPagoRecibido },
  data: () => ({
    headers_totales: [
      {
        text: "Opciones",
        align: "start",
        value: "actions",
        align: "center",
        width: 50,
      },
      {
        text: "Forma de pago",
        align: "start",
        value: "payment_method_type_id",
        width: 40,
      },
      { text: "Monto ", align: "end", value: "monto" },
      {
        text: "Forma de pago ID",
        align: "start",
        value: "payment_method_type_id",
        hide: true,
      },
    ],

    headers: [
      {
        text: "Opciones",
        align: "start",
        value: "actions",
        align: "center",
        width: 50,
      },
      { text: "Pago", align: "start", value: "payment_id", width: 80 },
      { text: "Factura", align: "start", value: "invoice_number" },
      { text: "Cliente", align: "start", value: "cliente_nombres" },
      { text: "Forma de pago", align: "start", value: "payment_method_type" },
      { text: "Tipo tarjeta", align: "start", value: "tipo_tc" },
      { text: "Referencia", align: "start", value: "payment_ref_num" },
      { text: "Monto aplicado", align: "end", value: "monto_aplicado" },
      { text: "Monto del pago", align: "end", value: "monto_pago" },
    ],

    headers_dep_efe: [
      {
        text: "referencia",
        align: "start",
        value: "referencia",
        align: "center",
        width: 50,
      },
      { text: "Monto ", align: "end", value: "monto" },
      { text: "Observaciones ", align: "start", value: "comments" },
    ],

    headers_facturas_caja: [
      {
        text: "Numero",
        align: "start",
        value: "numero_factura",
        align: "center",
        width: 100,
      },
      {
        text: "Fecha fa",
        align: "start",
        value: "invoice_date",
        align: "center",
        width: 40,
      },
      { text: "Plazo ", align: "center", value: "dias_plazo", width: 60 },
      { text: "Tipo ", align: "center", value: "tipo", width: 60 },
      { text: "Cliente", align: "start", value: "cliente", width: 100 },
      { text: "Cobrado", align: "end", value: "cobro", width: 40 },
      { text: "Usuario caja", align: "start", value: "user_id", width: 50 },
      { text: "Facturado por", align: "start", value: "ordered_by", width: 50 },
      { text: "Fecha cobro", align: "start", value: "fecha", width: 50 },
      {
        text: "Envio",
        align: "start",
        value: "shipment_method_type_id",
        width: 50,
        show: false,
      },
    ],

    dtTransCaja: [],
    options: {},

    search: "",
    tituloDeposito: "Depósito en enfectivo",
    headersTransfItems: [],
    dtTotalesCaja: [],

    formDepEfe: false,
    datosDepEfe: {},
    noEditarValor: false,
    noEditarDep: false,

    formBorrarDepCheq: false,
    tipoDeposito: "",
    fechaCaja: "",
    usuarioCaja: "",
    estadoCaja: "",
    codEstadoCaja: "",
    cajaId: "",
    buscar: "",

    cuentasBancarias: [],

    valorRules: [
      (v) => v > 0 || "El valor debe ser mayor as cero",
      (v) => !!v || "El campo es requerido",
    ],
    finAcctgRules: [(v) => !!v || "El campo es obligatorio"],
    referenciaRules: [(v) => !!v || "El campo es obligatorio"],
    fechaDepRules: [(v) => !!v || "Ingrese la fecha del depósito"],
    alertaTipo: "success",
    alertaColor: "green",
    alerta: false,

    overlay: false,
    formFacturas: false,
    popDialog: true,
    dtFacturasCaja: [],
    lbDepEfe: false,
    dialogAnticipos: false,
    dialogAnticipoCerrado: false,
  }),
  computed: {
    ...mapState("master", ["loadingTable", "user", "tenantId", "urlExternal"]),
    ...mapGetters("access", ["btnAbrirCaja"]),
  },
  methods: {
    ...mapMutations("master", ["setUrl", "setMenu", "setLoadingTable"]),
    ...mapActions("master", ["requestApi", "alertNotification"]),

    cargarTransaccionesCaja() {
      this.dtCajas = [];
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;
      this.setLoadingTable(true);

      if (
        this.$route.params.cajaId != "" &&
        this.$route.params.cajaId != null
      ) {
        this.cajaId = this.$route.params.cajaId;
      }
      this.overlay = true;
      this.setUrl("cajas/" + this.cajaId);
      this.requestApi({
        method: "GET",
        data: {},
      }).then((res) => {
        this.cajaId = res.data.datosCaja.caja_id;
        this.usuarioCaja = res.data.datosCaja.user_id;
        this.fechaCaja = res.data.datosCaja.fecha;
        this.estadoCaja = res.data.datosCaja.caja_estado;
        this.codEstadoCaja = res.data.datosCaja.estado_actual;
        this.dtTransCaja = res.data.transaccionesCaja;
        this.dtTotalesCaja = res.data.totalesFpCaja;
        this.datosDepEfe = res.data.depEfectivo;
        this.setLoadingTable(false);
        this.overlay = false;
        // console.log(this.dtTransCaja)
      });
    },

    guardarDeposito() {
      if (!this.$refs.formDeposito.validate()) {
        return false;
      }
      this.lbDepEfe = true;
      //console.log(this.datosDepEfe.referencia)
      if (this.tipoDeposito == "efe") {
        this.overlay = true;
        this.setUrl("deposito-efectivo");
        this.requestApi({
          method: "POST",
          data: {
            tenantId: this.tenantId,
            user_login_id: this.user.user_ligin_id,
            caja_id: this.cajaId,
            valor: this.datosDepEfe.valor,
            referencia: this.datosDepEfe.referencia,
            descripcion: this.datosDepEfe.descripcion,
            fecha_deposito: this.datosDepEfe.fechaDeposito,
            fin_account_id: this.datosDepEfe.fin_account_id,
          },
        })
          .then((res) => {
            //console.log(res.data.datos)
            this.lbDepEfe = false;
            this.formDepEfe = false;
            this.cargarTransaccionesCaja();
            this.overlay = false;
          })
          .then(() => {
            this.lbDepEfe = false;
            this.overlay = false;
          }).catch((err) => {
            this.overlay = false;
            this.lbDepEfe = false;
          });
      }

      if (this.tipoDeposito == "chq") {
        this.overlay = true;
        this.setUrl("deposito-cheque");
        this.requestApi({
          method: "POST",
          data: {
            tenantId: this.tenantId,
            user_login_id: this.user.user_ligin_id,
            caja_id: this.cajaId,
            valor: this.datosDepEfe.valor,
            referencia: this.datosDepEfe.referencia,
            descripcion: this.datosDepEfe.descripcion,
            fin_account_id: this.datosDepEfe.fin_account_id,
            fecha_deposito: this.datosDepEfe.fechaDeposito,
            caja_trans_id: this.datosDepEfe.caja_trans_id,
          },
        })
          .then((res) => {
            //console.log(res.data.datos)
            this.lbDepEfe = false;
            this.formDepEfe = false;
            this.cargarTransaccionesCaja();
            this.overlay = false;
          })
          .then(() => {
            this.lbDepEfe = false;
            this.overlay = false;
          });
      }
    },
    descargarCaja() {
      this.setUrl("cajas");
      this.requestApi({
        method: "POST",
        data: {
          cajaId: this.cajaId,
        },
      })
        .then((res) => {
          //console.log(res.data.datos)

          var a = document.createElement("a");
          a.href = "data:" + res.data.ContentType + ";base64," + res.data.datos;
          a.download = res.data.nombreArchivo;
          a.click();
        })
        .then(() => { });
    },
    cerrarCaja() {
      if (confirm("Esta seguro de cerrar esta caja?")) {
        this.setUrl("caja-pos-user/" + this.cajaId);
        this.requestApi({
          method: "PATCH",
          data: {
            estado_id: "CJ_CERRADA",
          },
        })
          .then((res) => {
            this.codEstadoCaja = "CJ_CERRADA";
            //console.log(res.data.datos)
            this.$emit("cajaCerrada");
          })
          .then(() => { });
      }
    },

    borrarDeposito(finAccountTransId, glRecId) {
      if (!confirm("Está seguro de borrar  este depósito")) {
        return false;
      }
      if (glRecId != null && glRecId != "") {
        this.alertNotification({
          param: {
            html:
              "No se puede eliminar este depósito porque ya fue conciliado.",
            timer: 10000,
            title: "Error",
            icon: "mdi-cancel",
            confirmButtonColor: "red",
          },
        });
        return false;
      }

      if (this.tipoDeposito == "efe") {
        this.overlay = true;
        this.setUrl("deposito-efectivo");
        this.requestApi({
          method: "DELETE",
          data: {
            fin_account_trans_id: finAccountTransId,
          },
          async: false,
        })
          .then((res) => {
            //console.log(res.data.datos)
            this.formDepEfe = false;
            this.cargarTransaccionesCaja();
          })
          .then(() => { });
      }

      if (this.tipoDeposito == "chq") {
        this.overlay = true;
        this.setUrl("deposito-cheque");
        this.requestApi({
          method: "DELETE",
          data: {
            fin_account_trans_id: finAccountTransId,
          },
          async: false,
        })
          .then((res) => {
            //console.log(res.data.datos)
            this.formDepEfe = false;
            this.cargarTransaccionesCaja();
          })
          .then(() => { });
      }
    },

    cargarCuentasBancarias() {
      this.setLoadingTable(true);

      this.setUrl("cuentas-bancarias");
      this.requestApi({
        method: "GET",
        data: {},
      }).then((res) => {
        this.cuentasBancarias = res.data._embedded.cuentas_bancarias;
        this.setLoadingTable(false);
      });
    },
    abrirFormDeposito() {
      this.noEditarValor = false;
      this.noEditarDep = false;
      this.formBorrarDepCheq = false;
      this.datosDepEfe.caja_trans_id = "";
      this.datosDepEfe.valor = "0.00";
      this.datosDepEfe.referencia = "";
      this.datosDepEfe.descripcion = "";
      this.tipoDeposito = "efe";
      this.tituloDeposito = "Depósito en efectivo";
      this.formDepEfe = true;
      this.lbDepEfe = false;
    },

    abrirFormCheque(tranId, monto, depBanco) {
      this.noEditarValor = true;
      this.noEditarDep = false;

      this.formBorrarDepCheq = false;

      this.datosDepEfe.caja_trans_id = tranId;
      this.datosDepEfe.valor = monto;

      this.tipoDeposito = "chq";
      this.tituloDeposito = "Depósito en cheque";
      this.formDepEfe = true;
    },
    borrarDepCheque(tranId, monto, depBanco) {
      this.noEditarDep = true;
      this.noEditarValor = true;
      this.formBorrarDepCheq = true;

      this.datosDepEfe.caja_trans_id = tranId;
      this.datosDepEfe.valor = monto;

      if (depBanco !== null) {
        this.datosDepEfe.descripcion = depBanco.comments;
        this.datosDepEfe.fin_account_id = depBanco.fin_account_id;
        this.datosDepEfe.referencia = depBanco.referencia;
        this.datosDepEfe.fin_account_trans_id = depBanco.fin_account_trans_id;
        if (depBanco.glRecId != null && depBanco.glRecId != "") {
          //alert('no se puede borrar el deposito')
          this.alertNotification({
            param: {
              html:
                "No se puede eliminar este depósito porque ya fue conciliado.",
              timer: 10000,
              title: "Error",
              icon: "mdi-cancel",
              confirmButtonColor: "red",
            },
          });
          return false;
        }
      }

      this.tipoDeposito = "chq";
      this.tituloDeposito = "Borrar Depósito en cheque";
      this.formDepEfe = true;
    },

    validarNumComp() {
      return false;
    },

    facturasCaja() {
      this.formFacturas = true;

      this.setUrl("facturas-caja");
      this.requestApi({
        method: "GET",
        data: {
          usuario: this.usuarioCaja,
          fecha: this.fechaCaja,
        },
      })
        .then((res) => {
          console.log(res.data.datos);
          //this.$emit("cajaCerrada")
          this.dtFacturasCaja = res.data._embedded.facturas_caja;
        })
        .then(() => { });
    },

    abrirCaja() {
      if (confirm("Esta seguro de abrir  esta caja?")) {
        this.setUrl("caja-pos-user/" + this.cajaId);
        this.requestApi({
          method: "PATCH",
          data: {
            estado_id: "CJ_ABIERTA",
          },
        })
          .then((res) => {
            this.codEstadoCaja = "CJ_ABIERTA";
            //console.log(res.data.datos)
            //this.$emit("cajaAbierta")
          })
          .then(() => { });
      }
    },
    ingresarAnticipo() {
      this.popDialog = true;
      this.dialogAnticipos = true;
    },
  },

  watch: {
    options: {
      handler() {
        this.cargarTransaccionesCaja();
      },
      deep: true,
    },
  },
  mounted() {
    this.cajaId = this.caja;
    this.cargarCuentasBancarias();
    //this.setMenu(this.menu)
  },
};
</script>
