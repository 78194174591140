<template>
    <div>
     <v-card v-if="cajaCerrada == true">
        <v-card-title>
          <span class="headline">Abrir caja</span>
        </v-card-title>
		<v-card-text>
			<v-container>
				<v-row>
					<v-col class="pt-0 pb-0"
						cols="4"
						sm="4"
						md="4"
					>
						<v-select
							v-model="terminalId"
							small
							item-text="terminal_name"
							item-value="pos_terminal_id"
							:items="terminalesUsuario"
							label="Terminal*"
							required
						></v-select>
					</v-col>
				</v-row>
				<v-row>
					<v-col class="pt-0 pb-0"
						cols="4"
						sm="4"
						md="4"
					>
						<v-text-field
							v-model="saldoInicial"                  
							required
							label="Saldo inicial"
						></v-text-field>
					</v-col>
				</v-row>
				
				<v-row>
					<v-col cols="6"
						sm="6"
						md="6"
					>
						<v-btn
							color="blue darken-1"
							solid
							class = "white--text"
							@click="abrirCaja()"
						>
							Abrir
						</v-btn>
					</v-col>
				</v-row>
			</v-container>
        </v-card-text>
     </v-card>
    <FormCajaComponent 
		v-if="cajaCerrada == false"
		@cajaCerrada="cajaCerrada=true"
		:caja="cajaId" 
	/>
    </div>
</template>

<script>
import {mapState, mapMutations, mapActions} from 'vuex'
import FormCajaComponent from '../contabilidad/FormCajaComponent'

    export default {
        name:'CashTransactionsComponent',
        components: {
        	FormCajaComponent
        },
        data: ()=> ({
        	cajaCerrada: true,
        	userId: "",
        	cajaId: null,
        	terminalId: "",
        	terminalesUsuario:[],
            saldoInicial: 0
        }),
        watch:{
            terminalId: function(val){
              console.log(val)
              this.setPosTerminalId(val)
            }
        },
        computed: {
        	...mapState('master',['user', 'loadingTable']),
        	...mapState('pos',['menu'])
        }
       	,methods: {
    	   	 ...mapMutations('master',['setUrl','setOverlay','setMenu','setLoadingTable','setPosTerminalId']),
    	   	 
    	     ...mapActions('master',['requestApi']),
    	     
    	     
    	   	
    	   	 abrirCaja() {
    	   		 
    	   		this.setUrl('caja-pos-user')
           	 	this.requestApi({
                    method: 'POST',
                    data :{
                        "tenantId" : "",
                        "user_id" : this.user.user_ligin_id,
                        "saldo_inicial": this.saldoInicial
                    }
                }).then(res =>{

                    //console.log(res.data.detail)
                    if(res.data.detail.cajaId == null) {
                    	alert('Error: ' + res.data.detail.msg);
                    	this.cajaId = res.data.detail.cajaId;
                    	this.cajaCerrada = true;
                    } else {
                    	this.cajaId = res.data.detail.cajaId;
                    	this.cajaCerrada = false;
						
						typeof this.$route.query !='undefined' && this.$route.query.pos =='true' &&
							this.$router.push('/punto_venta')

                    }  
                    
                }).then(()=>{
                  
                })
    	   			 
    	   	 },
    	   	 cargarTerminales() {
    	        	this.setUrl('terminal-user/'+this.user.user_ligin_id)
    	       	 	this.requestApi({
    	                method: 'GET',
    	                data :{
    	                    
    	                }
    	            }).then(res =>{

    	                //console.log(res.data.datos)
    	                this.terminalesUsuario = res.data.terminales
    	                
    	                  
    	                
    	            }).then(()=>{
    	              
    	            })
    	        },
    	        
       	},
       	mounted() {
       		
       		this.cargarTerminales();
       		
       		this.setUrl('caja-pos-user/' + this.user.user_ligin_id )
            this.requestApi({
                method : 'GET',
                data : {
                	 
                }
            }).then(res=>{
              
				
                this.cajaId = res.data.cajaAbierta
				
                if(this.cajaId == null ) {
    	       		this.cajaCerrada = true
    	       		this.cajaId = ""
    	       	} else {
    	       		this.cajaCerrada = false	       			
    	       	}
               
            });
       		       		
           // this.setMenu(this.menu)
        },
    }
</script>

<style>

</style>